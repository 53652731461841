var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CDropdown",
    {
      staticClass: "header-country-dd",
      attrs: { color: "link", caret: true },
      scopedSlots: _vm._u([
        {
          key: "toggler-content",
          fn: function () {
            return [
              _c("CIcon", {
                staticClass: "mr-1",
                attrs: {
                  content: _vm.$options.flagSet[_vm.country.flag],
                  size: "lg",
                },
              }),
              _c("span", [_vm._v(_vm._s(_vm.country.label))]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    _vm._l(_vm.authCountries, function (c, index) {
      return _c(
        "CDropdownItem",
        { key: index, on: { click: () => (_vm.country = c.value) } },
        [_vm._v(" " + _vm._s(c.label) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }