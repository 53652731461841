var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-items",
      attrs: { inNav: "", placement: "bottom-end", "add-menu-classes": "pt-0" },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function () {
            return [
              _c("CHeaderNavLink", [
                _c(
                  "div",
                  { staticClass: "c-avatar" },
                  [
                    _c("CImg", {
                      staticClass: "c-avatar-img",
                      staticStyle: { width: "36px", height: "36px" },
                      attrs: {
                        src:
                          _vm.userData?.user.image_icon ||
                          _vm.userData?.user.gravatar,
                        placeholderColor: "lightgray",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center", attrs: { tag: "div", color: "light" } },
        [
          _vm.userData
            ? _c("div", [
                _c("strong", [_vm._v(_vm._s(_vm.userData.user.first_name))]),
                _c("br"),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      `${_vm.userData.user.email} (#${_vm.userData.user.id})`
                    )
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "CDropdownItem",
        { on: { click: _vm.logout } },
        [
          _c("CIcon", { attrs: { name: "cil-lock-locked" } }),
          _vm._v(" Logout "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }