var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CDropdown",
    {
      staticClass: "dd-emails c-header-nav-item mx-0 mx-sm-2",
      attrs: {
        caret: false,
        placement: "bottom-end",
        "in-nav": "",
        "add-menu-classes": "pt-0",
        show: _vm.show,
      },
      on: {
        "update:show": function ($event) {
          _vm.show = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function () {
            return [
              _c(
                "CHeaderNavLink",
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-envelope-${
                        _vm.crm.hasUnreadMail ? "closed" : "open"
                      }`,
                      size: "lg",
                    },
                  }),
                  _vm.crm.hasUnreadMail
                    ? _c(
                        "CBadge",
                        {
                          staticClass: "badge-point",
                          attrs: { shape: "pill", color: "danger" },
                        },
                        [_vm._v(" ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center bg-light", attrs: { tag: "div" } },
        [
          _vm.loading
            ? _c("CSpinner", { attrs: { size: "sm" } })
            : _c("strong", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.emails.length > 0
                        ? `You have ${_vm.emails.length} messages`
                        : "No new message!"
                    ) +
                    " "
                ),
              ]),
        ],
        1
      ),
      _vm._l(_vm.emails, function (email) {
        return [
          _c(
            "CDropdownItem",
            {
              attrs: {
                to: {
                  name: "Message",
                  params: { id: email.message_id, folder: "inbox" },
                },
              },
            },
            [
              _c("div", { staticClass: "message" }, [
                _c("div", { staticClass: "pt-3 mr-3 float-left" }, [
                  _c(
                    "div",
                    { staticClass: "c-avatar" },
                    [
                      _c("CImg", {
                        staticClass: "c-avatar-img",
                        attrs: {
                          src:
                            email.message.sender?.image_icon ||
                            email.message.sender?.gravatar,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  [
                    email.message.is_outgoing
                      ? _c("CBadge", { attrs: { color: "light" } }, [
                          _c(
                            "span",
                            { staticClass: "text-info" },
                            [
                              _c("CIcon", { attrs: { name: "cil-send" } }),
                              _vm._v(" Outgoing Email"),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(
                        _vm._s(
                          email.message.is_outgoing
                            ? `${email.message.reply_to?.toString()}`
                            : email.message.sender?.first_name
                        )
                      ),
                    ]),
                    _c(
                      "small",
                      { staticClass: "text-muted float-right mt-1" },
                      [
                        _vm._v(
                          _vm._s(_vm.moment(email.message.created_at).fromNow())
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-truncate font-weight-bold" },
                  [
                    email.isMyTask
                      ? _c("CIcon", {
                          staticClass: "text-danger",
                          attrs: { name: "cil-bolt" },
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(email.message.subject) + " "),
                  ],
                  1
                ),
                _c("div", { staticClass: "small text-muted text-truncate" }, [
                  _vm._v(_vm._s(email.message.plain_reply)),
                ]),
              ]),
            ]
          ),
        ]
      }),
      _c(
        "CDropdownItem",
        { staticClass: "border-top", attrs: { to: { name: "Email" } } },
        [_c("strong", [_vm._v("View all messages")])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }