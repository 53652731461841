<template>
  <CDataTable
    class="table-couriers"
    striped
    hover
    :items="courierList"
    :fields="fields"
    :noItemsView="{
      noResults: 'No filtering results are available!',
      noItems: 'No couriers found!',
    }"
    clickable-rows
    @row-clicked="rowClicked"
  >
    <!-- ID -->
    <template #id="{item}">
      <td>
        #{{ item?.id }}
      </td>
    </template>

    <!-- User Icon -->
    <template #image_icon="{item}">
      <td>
        <CImg
          :src="item.image_icon || item.gravatar"
          class="c-avatar-img"
          style="width: 36px; height: 36px"
          placeholderColor="lightgray"
        />
      </td>
    </template>

    <!-- Name -->
    <template #name="{item}">
      <td>
        {{ item.first_name }}
      </td>
    </template>

    <!-- Contact -->
    <template #contact="{item}">
      <td>
        <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
        <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
      </td>
    </template>


    <!-- Vehicle -->
    <template #vehicle="{item}">
      <td>
        <div v-if="item.vehicle" class="d-flex align-items-center flex-gap-2">
          <MIcon :tag="item.vehicle.mode" />
          <strong class="text-uppercase">{{ item.vehicle.license_plate }}</strong>
        </div>
      </td>
    </template>

    <!-- Created -->
    <template #created_at="{item}">
      <td>{{ moment(item.pivot?.created_at).format('LL') }}</td>
    </template>

    <!-- Actions -->
    <template #actions="{ item, index }">
      <td>
        <CButton
          size="sm"
          color="danger"
          variant="ghost"
          shape="pill"
          :disabled="item.is_deletable !== true"
          @click="item.is_deletable === true && $delete(courierList, index)"
        >
          <CIcon name="cil-trash" />
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>

<script>
export default {
  name: "CourierList",
  props: {
    couriers: { default: () => [], type: Array },
  },
  data() {
    return {
      fields: [
        { key: "id", label: "ID", _style: "min-width: 60px; width: 60px;" },
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "name", _style: "min-width: 140px;" },
        { key: "contact", _style : "min-width: 200px;" },
        { key: "vehicle", _style : "min-width: 200px;" },
        { key: "created_at", label: "Appointment", filter: false, _style: "min-width: 140px; width: 140px;" },
        { key: "actions", label: "", filter: false, sorter: false, _style: "min-width: 48px; width: 48px;" },
      ],
    };
  },

  computed: {
    courierList: {
      get() {
        return this.couriers;
      },
      set(newValue) {
        this.$emit("update:couriers", newValue);
      },
    },
  },

  methods: {
    rowClicked(item, index, column, e) {
      const exclude = [
        "INPUT",
        "LABEL",
        "BUTTON",
        "svg",
        "path",
        "A",
        // "IMG",
        // "SPAN",
        // "DIV",
        "rect",
        // "STRONG",
      ];

      if (!exclude.includes(e.target.tagName)) {
        this.$router.push({ name: 'User', params: { id: item.id } })
      }
    },
  },
};
</script>

<style>
.table-couriers th,
.table-couriers td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}

.table-couriers th {
  border-top: none;
}
</style>
