var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CFooter", { attrs: { fixed: false } }, [
    _c("div", [
      _vm._v(" Copyright © " + _vm._s(new Date().getFullYear()) + " "),
      _c("a", { attrs: { href: "https://menute.be", target: "_blank" } }, [
        _vm._v("Menute.be"),
      ]),
    ]),
    _c("div", { staticClass: "mfs-auto" }, [
      _c("span", [_vm._v("Admin Panel v1.0")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }