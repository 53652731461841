var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-item mx-0 mx-sm-2",
      attrs: {
        caret: false,
        placement: "bottom-end",
        "in-nav": "",
        "add-menu-classes": "pt-0",
        show: _vm.show,
      },
      on: {
        "update:show": function ($event) {
          _vm.show = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function () {
            return [
              _c(
                "CHeaderNavLink",
                [
                  _c("CIcon", { attrs: { name: "cil-list-rich", size: "lg" } }),
                  _vm.crm.hasPendingTask
                    ? _c(
                        "CBadge",
                        {
                          staticClass: "badge-point",
                          attrs: { shape: "pill", color: "warning" },
                        },
                        [_vm._v(" ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center bg-light", attrs: { tag: "div" } },
        [
          _vm.loading
            ? _c("CSpinner", { attrs: { size: "sm" } })
            : _c("strong", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tasks.length > 0
                        ? `You have ${_vm.tasks.length} pending tasks`
                        : "You have no pending task!"
                    ) +
                    " "
                ),
              ]),
        ],
        1
      ),
      _vm._l(_vm.tasks, function (task) {
        return [
          _c(
            "CDropdownItem",
            {
              staticClass: "d-block",
              attrs: {
                to: {
                  name: "Message",
                  params: { id: task.task_id, folder: "inbox" },
                },
              },
            },
            [
              _c("div", { staticClass: "small mb-1" }, [
                _vm._v(" " + _vm._s(task.subject) + " "),
                _c("span", { staticClass: "float-right" }),
              ]),
              _c("CProgress", {
                staticClass: "progress-xs",
                attrs: {
                  color: task.labelObj.color,
                  value: task.labelObj.progress,
                },
              }),
            ],
            1
          ),
        ]
      }),
      _c(
        "CDropdownItem",
        { staticClass: "border-top", attrs: { to: { name: "Dashboard" } } },
        [_c("strong", [_vm._v("View all tasks")])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }