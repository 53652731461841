var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CHeader",
    { attrs: { "with-subheader": "" } },
    [
      _c("CToggler", {
        staticClass: "ml-3 d-lg-none",
        attrs: { "in-header": "" },
        on: {
          click: function ($event) {
            return _vm.$store.commit("toggleSidebarMobile")
          },
        },
      }),
      _c("CToggler", {
        staticClass: "ml-3 d-md-down-none",
        attrs: { "in-header": "" },
        on: {
          click: function ($event) {
            return _vm.$store.commit("toggleSidebarDesktop")
          },
        },
      }),
      _c(
        "CHeaderBrand",
        { staticClass: "mx-auto d-lg-none", attrs: { to: "/" } },
        [
          _c("img", {
            staticClass: "d-none d-sm-block img-drop-shadow",
            attrs: {
              src: "/img/menute.be/logo.png",
              width: "150",
              alt: "Menute.be",
            },
          }),
          _c("img", {
            staticClass: "d-block d-sm-none img-drop-shadow",
            attrs: {
              src: "/img/menute.be/logo.png",
              width: "120",
              alt: "Menute.be",
            },
          }),
        ]
      ),
      _c(
        "CHeaderNav",
        { staticClass: "d-md-down-none mr-auto" },
        [
          _c(
            "CHeaderNavItem",
            { staticClass: "px-2" },
            [
              _c("CHeaderNavLink", { attrs: { to: "/dashboard" } }, [
                _vm._v(" Dashboard "),
              ]),
            ],
            1
          ),
          _c("CHeaderNavItem", [_c("CountryDD")], 1),
        ],
        1
      ),
      _c(
        "CHeaderNav",
        [
          _c(
            "CHeaderNavItem",
            { staticClass: "c-d-legacy-none mr-0 mr-sm-2" },
            [
              _c(
                "a",
                {
                  staticClass: "c-header-nav-btn",
                  attrs: { href: "#", onclick: "javascript:window.print();" },
                },
                [_c("CIcon", { attrs: { name: "cil-print", size: "lg" } })],
                1
              ),
            ]
          ),
          !_vm.restrictedUser
            ? [_c("TheHeaderDropdownTasks"), _c("TheHeaderDropdownMssgs")]
            : _vm._e(),
          _c("CHeaderNavItem", { staticClass: "d-none d-md-block" }, [
            _c(
              "button",
              { staticClass: "c-header-nav-btn", on: { click: _vm.logout } },
              [_c("CIcon", { attrs: { name: "cil-lock-locked", size: "lg" } })],
              1
            ),
          ]),
          _c("TheHeaderDropdownAccnt", { staticClass: "mr-0 mr-sm-3" }),
        ],
        2
      ),
      _c(
        "CSubheader",
        { staticClass: "px-3" },
        [_c("CBreadcrumbRouter", { staticClass: "border-0 mb-0" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }